import React from 'react'

import RightSheetConsumer from './RightSheetConsumer'

const RightSheetRoot: React.FC = () => {
  return (
    <RightSheetConsumer>
      {({ Component, props, hideRightSheet }) =>
        Component ? (
          <Component
            {...props}
            onCloseRequest={(data: any) => {
              if (props.onCloseRequest) props.onCloseRequest(data)
              hideRightSheet()
            }}
          />
        ) : null
      }
    </RightSheetConsumer>
  )
}

export default RightSheetRoot
