import Router from 'Router'
import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.min.css'

import './App.css'
import './style/main.out.css'

const appId = process.env.REACT_APP_ONESIGNAL_APP_ID
const safariId = process.env.REACT_APP_ONESIGNAL_SAFARI_ID

function App() {
  useEffect(() => {
    if (appId && safariId) {
      OneSignal.init({
        appId: appId,
        safari_web_id: safariId,
        notifyButton: {
          enable: false,
        },
        allowLocalhostAsSecureOrigin: true,
      }).catch(() => {
        // DO NOTHING
      })
    }
  }, [])

  return <Router />
}

export default App
