import { MessageEvent } from './MessageEvent'
import MessageState from './contract/MessageState'

const initialState: MessageState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const holidayReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MessageEvent.DELETED_MESSAGES_FETCHED:
    case MessageEvent.MESSAGES_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case MessageEvent.DELETED_MESSAGE_FETCHED:
    case MessageEvent.MESSAGE_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case MessageEvent.MESSAGE_ADDED:
    case MessageEvent.MESSAGE_DELETED:
    case MessageEvent.MESSAGE_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case MessageEvent.MESSAGE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case MessageEvent.MESSAGE_ERROR:
      return {
        ...state,
        all: [],
        single: {},
        loading: false,
      }

    case MessageEvent.MESSAGE_RESET:
      return {
        ...state,
        single: {},
      }

    case MessageEvent.MESSAGES_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default holidayReducer
