import PopupConsumer from 'common/component/util/popup/PopupConsumer'
import React from 'react'

const PopupRoot: React.FC = () => {
  return (
    <PopupConsumer>
      {({ Component, props, hidePopup }) =>
        Component ? <Component {...props} onCloseRequest={hidePopup} /> : null
      }
    </PopupConsumer>
  )
}

export default PopupRoot
