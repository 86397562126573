import { AuthActionType } from 'auth/state/contract/AuthActionType'
import AuthState from 'auth/state/contract/AuthState'

const initialState: AuthState = {
  auth: false,
  user: {} as any,
  menu: {},
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return {
        ...state,
      }
    case AuthActionType.LOGOUT:
      return {
        ...state,
        auth: false,
      }
    case AuthActionType.LOGGED_IN_USER_FETCHED:
      return {
        ...state,
        user: action.payload,
        auth: true,
      }
    case AuthActionType.ACCESS_MENU_FETCHED:
      return {
        ...state,
        menu: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default authReducer
