import { AttendanceActionType } from './AttendanceActionType'
import AttendanceState from './contract/AttendanceState'

const initialState: AttendanceState = {
  all: [],
  single: {} as any,
  approvals: [],
  error: false,
  loading: false,
}

const attendanceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AttendanceActionType.ATTENDANCES_FETCHED:
      return {
        ...state,
        all: [...state.all, ...(action.payload || [])],
        loading: false,
      }

    case AttendanceActionType.ATTENDANCES_REPLACED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case AttendanceActionType.ATTENDANCE_APPROVALS_FETCHED:
      return {
        ...state,
        approvals: action.payload || [],
        loading: false,
      }

    case AttendanceActionType.ATTENDANCE_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case AttendanceActionType.ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case AttendanceActionType.RESET_ATTENDANCES:
      return {
        ...state,
        loading: false,
        all: [],
      }

    case AttendanceActionType.RESET_ATTENDANCE_APPROVALS:
      return {
        ...state,
        loading: false,
        approvals: [],
      }

    case AttendanceActionType.ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default attendanceReducer
