import { DivisionActionType } from './DivisionActionType'
import DivisionState from './contract/DivisionState'

const initialState: DivisionState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const divisionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DivisionActionType.DELETED_DIVISIONS_FETCHED:
    case DivisionActionType.DIVISIONS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case DivisionActionType.DELETED_DIVISION_FETCHED:
    case DivisionActionType.DIVISION_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case DivisionActionType.DIVISION_ADDED:
    case DivisionActionType.DIVISION_DELETED:
    case DivisionActionType.DIVISION_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case DivisionActionType.DIVISION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case DivisionActionType.DIVISION_ERROR:
      return {
        ...state,
        loading: false,
      }

    case DivisionActionType.DIVISION_RESET:
      return {
        ...state,
        single: {},
      }

    case DivisionActionType.DIVISIONS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default divisionReducer
