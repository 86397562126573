export enum ApprovalRuleEvent {
  APPROVAL_RULES_FETCHED = 'APPROVAL_RULES_FETCHED',
  APPROVAL_RULE_FETCHED = 'APPROVAL_RULE_FETCHED',
  DELETED_APPROVAL_RULES_FETCHED = 'DELETED_APPROVAL_RULES_FETCHED',
  DELETED_APPROVAL_RULE_FETCHED = 'DELETED_APPROVAL_RULE_FETCHED',
  APPROVAL_RULE_LOADING = 'APPROVAL_RULE_LOADING',
  APPROVAL_RULE_ERROR = 'APPROVAL_RULE_ERROR',
  APPROVAL_RULE_ADDED = 'APPROVAL_RULE_ADDED',
  APPROVAL_RULE_UPDATED = 'APPROVAL_RULE_UPDATED',
  APPROVAL_RULE_DELETED = 'APPROVAL_RULE_DELETED',
  APPROVAL_RULE_RESTORED = 'APPROVAL_RULE_RESTORED',
  APPROVAL_RULE_RESET = 'APPROVAL_RULE_RESET',
  APPROVAL_RULES_RESET = 'APPROVAL_RULES_RESET',
}
