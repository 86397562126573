export enum ApprovalCcEventType {
  APPROVAL_CCS_FETCHED = 'APPROVAL_CCS_FETCHED',
  APPROVAL_CC_FETCHED = 'APPROVAL_CC_FETCHED',
  DELETED_APPROVAL_CCS_FETCHED = 'DELETED_APPROVAL_CCS_FETCHED',
  DELETED_APPROVAL_CC_FETCHED = 'DELETED_APPROVAL_CC_FETCHED',
  APPROVAL_CC_LOADING = 'APPROVAL_CC_LOADING',
  APPROVAL_CC_ERROR = 'APPROVAL_CC_ERROR',
  APPROVAL_CC_ADDED = 'APPROVAL_CC_ADDED',
  APPROVAL_CC_UPDATED = 'APPROVAL_CC_UPDATED',
  APPROVAL_CC_DELETED = 'APPROVAL_CC_DELETED',
  APPROVAL_CC_RESTORED = 'APPROVAL_CC_RESTORED',
  APPROVAL_CC_RESET = 'APPROVAL_CC_RESET',
  APPROVAL_CCS_RESET = 'APPROVAL_CCS_RESET',
}
