export enum ApprovalActionType {
  APPROVALS_FETCHED = 'APPROVALS_FETCHED',
  APPROVAL_FETCHED = 'APPROVAL_FETCHED',
  APPROVAL_ADDED = 'APPROVAL_ADDED',
  APPROVAL_DELETED = 'APPROVAL_DELETED',
  APPROVAL_UPDATED = 'APPROVAL_UPDATED',
  REVIEWED = 'REVIEWED',
  REVIEW_CANCELED = 'REVIEW_CANCELED',
  IGNORED = 'IGNORED',
  IGNORE_CANCELED = 'IGNORE_CANCELED',
  APPROVALS_RESET = 'APPROVALS_RESET',
  APPROVAL_RESET = 'APPROVAL_RESET',
  REVIEWS_RESET = 'REVIEWS_RESET',
  REVIEW_RESET = 'REVIEW_RESET',
  APPROVAL_LOADING = 'APPROVAL_LOADING',
  APPROVAL_ERROR = 'APPROVAL_ERROR',
  REVIEWS_FETCHED = 'REVIEWS_FETCHED',
  REVIEW_FETCHED = 'REVIEW_FETCHED',
}
