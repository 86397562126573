import { NationalCutiActionType } from './NationalCutiActionType'
import NationalCutiState from './contract/NationalCutiState'

const initialState: NationalCutiState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const nationalCutiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NationalCutiActionType.DELETED_NATIONAL_CUTIS_FETCHED:
    case NationalCutiActionType.NATIONAL_CUTIS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case NationalCutiActionType.DELETED_NATIONAL_CUTI_FETCHED:
    case NationalCutiActionType.NATIONAL_CUTI_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case NationalCutiActionType.NATIONAL_CUTI_ADDED:
    case NationalCutiActionType.NATIONAL_CUTI_DELETED:
    case NationalCutiActionType.NATIONAL_CUTI_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case NationalCutiActionType.NATIONAL_CUTI_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case NationalCutiActionType.NATIONAL_CUTI_ERROR:
      return {
        ...state,
        loading: false,
      }

    case NationalCutiActionType.NATIONAL_CUTI_RESET:
      return {
        ...state,
        single: {},
      }

    case NationalCutiActionType.NATIONAL_CUTIS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default nationalCutiReducer
