import { EmployeeActionType } from 'module/employee/state/EmployeeActionType'
import EmployeeState from 'module/employee/state/contract/EmployeeState'

const initialState: EmployeeState = {
  all: [],
  single: {} as any,
  relations: [],
  error: false,
  loading: false,
}

const employeeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EmployeeActionType.EMPLOYEES_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case EmployeeActionType.EMPLOYEE_RELATION_FETCHED:
      return {
        ...state,
        relations: action.payload || [],
      }

    case EmployeeActionType.EMPLOYEE_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case EmployeeActionType.EMPLOYEE_DELETED:
    case EmployeeActionType.EMPLOYEE_UPDATED:
    case EmployeeActionType.EMPLOYEE_RESTORED:
    case EmployeeActionType.EMPLOYEE_ADDED:
      return {
        ...state,
        loading: false,
      }

    case EmployeeActionType.EMPLOYEE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case EmployeeActionType.EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
      }
    case EmployeeActionType.EMPLOYEES_RESET:
      return {
        ...state,
        all: [],
      }
    case EmployeeActionType.EMPLOYEE_RESET:
      return {
        ...state,
        single: {},
      }
    default:
      return {
        ...state,
      }
  }
}

export default employeeReducer
