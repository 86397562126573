export enum WorkTimeActionType {
  WORKTIMES_FETCHED = 'WORKTIMES_FETCHED',
  WORKTIME_FETCHED = 'WORKTIME_FETCHED',
  DELETED_WORKTIMES_FETCHED = 'DELETED_WORKTIMES_FETCHED',
  DELETED_WORKTIME_FETCHED = 'DELETED_WORKTIME_FETCHED',
  WORKTIME_LOADING = 'WORKTIME_LOADING',
  WORKTIME_ERROR = 'WORKTIME_EZRROR',
  WORKTIME_ADDED = 'WORKTIME_ADDED',
  WORKTIME_UPDATED = 'WORKTIME_UPDATED',
  WORKTIME_DELETED = 'WORKTIME_DELETED',
  WORKTIME_RESTORED = 'WORKTIME_RESTORED',
  WORKTIME_RESET = 'WORKTIME_RESET',
  WORKTIMES_RESET = 'WORKTIMES_RESET',
}
