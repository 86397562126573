import React from 'react'

const PageLoader = () => {
  return (
    <img
      className="w-16 h-16 absolute"
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      src="/assets/animation/Loading.svg"
      alt="loader"
    />
  )
}

export default PageLoader
