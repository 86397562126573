import browserHistory from 'browserHistory'
import ModalRoot from 'common/component/util/modal/ModalRoot'
import { PopupProvider } from 'common/component/util/popup/PopupProvider'
import PopupRoot from 'common/component/util/popup/PopupRoot'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router as ReactRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { store } from 'redux/store'

import { RoutePath } from './RoutePath'
import PageLoader from './common/component/util/loader/PageLoader'
import { ModalProvider } from './common/component/util/modal/ModalProvider'
import { RightSheetProvider } from './common/component/util/rightSheet/RightSheetProvider'
import RightSheetRoot from './common/component/util/rightSheet/RightSheetRoot'
import ScrollDisabler from './common/component/util/scrollDisabler/ScrollDisabler'

const delayDuration = 600
const delay = (callback: any, duration: number) => {
  return new Promise<{ default: React.ComponentType<any> }>((resolve) => {
    setTimeout(() => resolve(callback), duration)
  })
}

const Router: React.FC = () => {
  return (
    <Provider store={store}>
      <ReactRouter history={browserHistory}>
        <HelmetProvider>
          <RightSheetProvider>
            <ModalProvider>
              <PopupProvider>
                <ScrollDisabler />
                <React.Suspense fallback={<PageLoader />}>
                  <Switch>
                    <Route
                      path={RoutePath.HOME}
                      component={React.lazy(() =>
                        delay(import('./common/page/home/Home'), delayDuration),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.WORK_TRIPS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/workTrip/page/workTrips/WorkTrips'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.POSITIONS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/position/page/positions/Positions'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.DIVISIONS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/division/page/divisions/Divisions'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.WORK_TIMES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/workTime/page/workTimes/WorkTimes'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.HOLIDAY}
                      component={React.lazy(() =>
                        delay(
                          import('./module/holiday/page/holidays/Holidays'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.LOCATIONS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/location/page/locations/Locations'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.SCHEDULES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/schedule/page/schedules/Schedules'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.WORKGROUPS}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/workgroup/page/workgroups/Workgroups'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.APPROVALS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/approval/page/Approvals'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.ATTENDANCES}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/attendance/page/attendances/Attendances'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.ISSUES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/issue/page/issues/Issues'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.EMPLOYEES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/employee/page/employees/Employees'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.MESSAGES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/message/page/messages/Messages'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.LETTERS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/letter/page/Letters'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.ANNOUNCEMENTS}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/announcement/page/announcements/Announcements'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.EVENTS}
                      component={React.lazy(() =>
                        delay(
                          import('./module/event/page/events/Events'),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.PUNISHMENTS}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/punishment/page/punishments/Punishments'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.PUNISHMENT_TYPES}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/punishmentType/page/PunishmentTypes'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.APPROVAL_CC}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/approvalCc/page/approvalCcs/ApprovalCcs'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.APPROVAL_RULE}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/approvalRule/page/approvalRules/ApprovalRules'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.NATIONAL_CUTI}
                      component={React.lazy(() =>
                        delay(
                          import(
                            './module/nationalCuti/page/nationalCuti/NationalCuti'
                          ),
                          delayDuration,
                        ),
                      )}
                      exact={true}
                    />
                    <Route
                      path={RoutePath.LOGIN}
                      component={React.lazy(() =>
                        delay(import('./auth/page/login/Login'), delayDuration),
                      )}
                    />
                    <Route
                      path={RoutePath.FORGOT_PASSWORD}
                      component={React.lazy(() =>
                        delay(
                          import('./common/page/forgotPassword/ForgotPassword'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.RESET_FORGOTTEN_PASSWORD}
                      component={React.lazy(() =>
                        delay(
                          import('./common/page/resetPassword/ResetPassword'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.HIRUPIRA}
                      component={React.lazy(() =>
                        delay(
                          import('./module/hirupira/page/Hirupira'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.TICKET}
                      component={React.lazy(() =>
                        delay(
                          import('./module/ticket/page/Ticket'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.VEHICLES}
                      component={React.lazy(() =>
                        delay(
                          import('./module/vehicle/page/Vehicle'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.TICKET_ADMIN}
                      component={React.lazy(() =>
                        delay(
                          import('./module/ticketAdmin/page/TicketAdmin'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.CHECKPOINT}
                      component={React.lazy(() =>
                        delay(
                          import('./module/checkpoint/page/CheckPoint'),
                          delayDuration,
                        ),
                      )}
                    />
                    <Route
                      path={RoutePath.SIGNATURE}
                      component={React.lazy(() =>
                        delay(
                          import('./module/signature/page/Signature'),
                          delayDuration,
                        ),
                      )}
                    />
                  </Switch>
                </React.Suspense>
                <RightSheetRoot />
                <ModalRoot />
                <PopupRoot />
              </PopupProvider>
            </ModalProvider>
          </RightSheetProvider>
        </HelmetProvider>
      </ReactRouter>
      <ToastContainer position="bottom-left" />
    </Provider>
  )
}

export default Router
