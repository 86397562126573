import { ScheduleActionType } from './ScheduleActionType'
import ScheduleState from './contract/ScheduleState'

const initialState: ScheduleState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const scheduleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ScheduleActionType.DELETED_SCHEDULES_FETCHED:
    case ScheduleActionType.SCHEDULES_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case ScheduleActionType.DELETED_SCHEDULE_FETCHED:
    case ScheduleActionType.SCHEDULE_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case ScheduleActionType.SCHEDULE_ADDED:
    case ScheduleActionType.SCHEDULE_DELETED:
    case ScheduleActionType.SCHEDULE_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case ScheduleActionType.SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case ScheduleActionType.SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
      }

    case ScheduleActionType.SCHEDULE_RESET:
      return {
        ...state,
        single: {},
      }

    case ScheduleActionType.SCHEDULES_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default scheduleReducer
