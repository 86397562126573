export enum NationalCutiActionType {
  NATIONAL_CUTIS_FETCHED = 'NATIONAL_CUTIS_FETCHED',
  NATIONAL_CUTI_FETCHED = 'NATIONAL_CUTI_FETCHED',
  DELETED_NATIONAL_CUTIS_FETCHED = 'DELETED_NATIONAL_CUTIS_FETCHED',
  DELETED_NATIONAL_CUTI_FETCHED = 'DELETED_NATIONAL_CUTI_FETCHED',
  NATIONAL_CUTI_LOADING = 'NATIONAL_CUTI_LOADING',
  NATIONAL_CUTI_ERROR = 'POSITION_ERROR',
  NATIONAL_CUTI_ADDED = 'NATIONAL_CUTI_ADDED',
  NATIONAL_CUTI_UPDATED = 'NATIONAL_CUTI_UPDATED',
  NATIONAL_CUTI_DELETED = 'NATIONAL_CUTI_DELETED',
  NATIONAL_CUTI_RESTORED = 'NATIONAL_CUTI_RESTORED',
  NATIONAL_CUTI_RESET = 'NATIONAL_CUTI_RESET',
  NATIONAL_CUTIS_RESET = 'NATIONAL_CUTIS_RESET',
}
