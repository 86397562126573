import React from 'react'

import ModalConsumer from '../modal/ModalConsumer'
import RightSheetConsumer from '../rightSheet/RightSheetConsumer'

const ScrollDisabler: React.FC = () => {
  return (
    <RightSheetConsumer>
      {({ Component }) => (
        <ModalConsumer>
          {({ Component: ModalC }) => (
            <InnerScrollDisabler rightSheet={Component} modal={ModalC} />
          )}
        </ModalConsumer>
      )}
    </RightSheetConsumer>
  )
}

interface InnerScrollDisablerProps {
  rightSheet?: React.ComponentType<any> | null
  modal?: React.ComponentType<any> | null
}

const InnerScrollDisabler: React.FC<InnerScrollDisablerProps> = (props) => {
  if (props.rightSheet || props.modal) document.body.style.overflowY = 'hidden'
  else document.body.style.overflowY = 'unset'
  return <></>
}

export default ScrollDisabler
