export enum AnnouncementEvent {
  ANNOUNCEMENTS_FETCHED = 'ANNOUNCEMENTS_FETCHED',
  ANNOUNCEMENT_FETCHED = 'ANNOUNCEMENT_FETCHED',
  DELETED_ANNOUNCEMENTS_FETCHED = 'DELETED_ANNOUNCEMENTS_FETCHED',
  DELETED_ANNOUNCEMENT_FETCHED = 'DELETED_ANNOUNCEMENT_FETCHED',
  ANNOUNCEMENT_LOADING = 'ANNOUNCEMENT_LOADING',
  ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR',
  ANNOUNCEMENT_ADDED = 'ANNOUNCEMENT_ADDED',
  ANNOUNCEMENT_UPDATED = 'ANNOUNCEMENT_UPDATED',
  ANNOUNCEMENT_DELETED = 'ANNOUNCEMENT_DELETED',
  ANNOUNCEMENT_RESTORED = 'ANNOUNCEMENT_RESTORED',
  ANNOUNCEMENT_RESET = 'ANNOUNCEMENT_RESET',
  ANNOUNCEMENTS_RESET = 'ANNOUNCEMENTS_RESET',
}
