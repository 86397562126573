import { EventEvent } from './EventEvent'
import EventState from './contract/EventState'

const initialState: EventState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const holidayReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EventEvent.DELETED_EVENTS_FETCHED:
    case EventEvent.EVENTS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case EventEvent.DELETED_EVENT_FETCHED:
    case EventEvent.EVENT_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case EventEvent.EVENT_ADDED:
    case EventEvent.EVENT_DELETED:
    case EventEvent.EVENT_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case EventEvent.EVENT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case EventEvent.EVENT_ERROR:
      return {
        ...state,
        loading: false,
      }

    case EventEvent.EVENT_RESET:
      return {
        ...state,
        single: {},
      }

    case EventEvent.EVENTS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default holidayReducer
