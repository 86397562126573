export enum WorkgroupActionType {
  WORKGROUPS_FETCHED = 'WORKGROUPS_FETCHED',
  WORKGROUP_FETCHED = 'WORKGROUP_FETCHED',
  DELETED_WORKGROUPS_FETCHED = 'DELETED_WORKGROUPS_FETCHED',
  DELETED_WORKGROUP_FETCHED = 'DELETED_WORKGROUP_FETCHED',
  WORKGROUP_LOADING = 'WORKGROUP_LOADING',
  WORKGROUP_ERROR = 'WORKGROUP_ERROR',
  WORKGROUP_ADDED = 'WORKGROUP_ADDED',
  WORKGROUP_UPDATED = 'WORKGROUP_UPDATED',
  WORKGROUP_DELETED = 'WORKGROUP_DELETED',
  WORKGROUP_RESTORED = 'WORKGROUP_RESTORED',
  WORKGROUP_RESET = 'WORKGROUP_RESET',
  WORKGROUPS_RESET = 'WORKGROUPS_RESET',
}
