import RightSheetContextState from 'common/component/util/rightSheet/RightSheetContextState'
import { createContext } from 'react'

const RightSheetContext = createContext<RightSheetContextState>({
  Component: null,
  props: {},
  showRightSheet: () => {},
  hideRightSheet: () => {},
})

export default RightSheetContext
