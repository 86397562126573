export enum PositionActionType {
  POSITIONS_FETCHED = 'POSITIONS_FETCHED',
  POSITION_FETCHED = 'POSITION_FETCHED',
  DELETED_POSITIONS_FETCHED = 'DELETED_POSITIONS_FETCHED',
  DELETED_POSITION_FETCHED = 'DELETED_POSITION_FETCHED',
  POSITION_LOADING = 'POSITION_LOADING',
  POSITION_ERROR = 'POSITION_EZRROR',
  POSITION_ADDED = 'POSITION_ADDED',
  POSITION_UPDATED = 'POSITION_UPDATED',
  POSITION_DELETED = 'POSITION_DELETED',
  POSITION_RESTORED = 'POSITION_RESTORED',
  POSITION_RESET = 'POSITION_RESET',
  POSITIONS_RESET = 'POSITIONS_RESET',
}
