export enum EmployeeActionType {
  EMPLOYEE_LOADING = 'EMPLOYEE_LOADING',
  EMPLOYEES_FETCHED = 'EMPLOYEES_FETCHED',
  EMPLOYEE_FETCHED = 'EMPLOYEE_FETCHED',
  EMPLOYEES_ERROR = 'EMPLOYEES_ERROR',
  EMPLOYEE_ADDED = 'EMPLOYEE_ADDED',
  EMPLOYEE_UPDATED = 'EMPLOYEE_UPDATED',
  EMPLOYEE_DELETED = 'EMPLOYEE_DELETED',
  EMPLOYEE_RESTORED = 'EMPLOYEE_RESTORED',
  EMPLOYEE_RELATION_FETCHED = 'EMPLOYEE_RELATION_FETCHED',
  EMPLOYEES_RESET = 'EMPLOYEES_RESET',
  EMPLOYEE_RESET = 'EMPLOYEE_RESET',
}
