import { ApprovalRuleEvent } from './ApprovalRuleEvent'
import ApprovalRuleState from './contract/ApprovalRuleState'

const initialState: ApprovalRuleState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const approvalRuleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ApprovalRuleEvent.DELETED_APPROVAL_RULES_FETCHED:
    case ApprovalRuleEvent.APPROVAL_RULES_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case ApprovalRuleEvent.DELETED_APPROVAL_RULE_FETCHED:
    case ApprovalRuleEvent.APPROVAL_RULE_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case ApprovalRuleEvent.APPROVAL_RULE_ADDED:
    case ApprovalRuleEvent.APPROVAL_RULE_DELETED:
    case ApprovalRuleEvent.APPROVAL_RULE_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case ApprovalRuleEvent.APPROVAL_RULE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case ApprovalRuleEvent.APPROVAL_RULE_ERROR:
      return {
        ...state,
        loading: false,
      }

    case ApprovalRuleEvent.APPROVAL_RULE_RESET:
      return {
        ...state,
        single: {},
      }

    case ApprovalRuleEvent.APPROVAL_RULES_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default approvalRuleReducer
