export enum HolidayActionType {
  HOLIDAYS_FETCHED = 'HOLIDAYS_FETCHED',
  HOLIDAY_FETCHED = 'HOLIDAY_FETCHED',
  DELETED_HOLIDAYS_FETCHED = 'DELETED_HOLIDAYS_FETCHED',
  DELETED_HOLIDAY_FETCHED = 'DELETED_HOLIDAY_FETCHED',
  HOLIDAY_LOADING = 'HOLIDAY_LOADING',
  HOLIDAY_ERROR = 'POSITION_ERROR',
  HOLIDAY_ADDED = 'HOLIDAY_ADDED',
  HOLIDAY_UPDATED = 'HOLIDAY_UPDATED',
  HOLIDAY_DELETED = 'HOLIDAY_DELETED',
  HOLIDAY_RESTORED = 'HOLIDAY_RESTORED',
  HOLIDAY_RESET = 'HOLIDAY_RESET',
  HOLIDAYS_RESET = 'HOLIDAYS_RESET',
}
