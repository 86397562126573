import { WorkgroupActionType } from './WorkgroupActionType'
import WorkgroupState from './contract/WorkgroupState'

const initialState: WorkgroupState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const workgroupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WorkgroupActionType.DELETED_WORKGROUPS_FETCHED:
    case WorkgroupActionType.WORKGROUPS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case WorkgroupActionType.DELETED_WORKGROUP_FETCHED:
    case WorkgroupActionType.WORKGROUP_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case WorkgroupActionType.WORKGROUP_ADDED:
    case WorkgroupActionType.WORKGROUP_DELETED:
    case WorkgroupActionType.WORKGROUP_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case WorkgroupActionType.WORKGROUP_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case WorkgroupActionType.WORKGROUP_ERROR:
      return {
        ...state,
        loading: false,
      }

    case WorkgroupActionType.WORKGROUP_RESET:
      return {
        ...state,
        single: {},
      }

    case WorkgroupActionType.WORKGROUPS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default workgroupReducer
