export enum DivisionActionType {
  DIVISIONS_FETCHED = 'DIVISIONS_FETCHED',
  DIVISION_FETCHED = 'DIVISION_FETCHED',
  DELETED_DIVISIONS_FETCHED = 'DELETED_DIVISIONS_FETCHED',
  DELETED_DIVISION_FETCHED = 'DELETED_DIVISION_FETCHED',
  DIVISION_LOADING = 'DIVISION_LOADING',
  DIVISION_ERROR = 'POSITION_EZRROR',
  DIVISION_ADDED = 'DIVISION_ADDED',
  DIVISION_UPDATED = 'DIVISION_UPDATED',
  DIVISION_DELETED = 'DIVISION_DELETED',
  DIVISION_RESTORED = 'DIVISION_RESTORED',
  DIVISION_RESET = 'DIVISION_RESET',
  DIVISIONS_RESET = 'DIVISIONS_RESET',
}
