import { PositionActionType } from 'module/position/state/PositionActionType'
import PositionState from 'module/position/state/contract/PositionState'

const initialState: PositionState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const positionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PositionActionType.DELETED_POSITIONS_FETCHED:
    case PositionActionType.POSITIONS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case PositionActionType.DELETED_POSITION_FETCHED:
    case PositionActionType.POSITION_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case PositionActionType.POSITION_ADDED:
    case PositionActionType.POSITION_DELETED:
    case PositionActionType.POSITION_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case PositionActionType.POSITION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case PositionActionType.POSITION_ERROR:
      return {
        ...state,
        loading: false,
      }

    case PositionActionType.POSITION_RESET:
      return {
        ...state,
        single: {},
      }

    case PositionActionType.POSITIONS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default positionReducer
