import { ApprovalActionType } from './ApprovalActionType'
import ApprovalState from './contract/ApprovalState'

const initialState: ApprovalState = {
  all: [],
  single: {} as any,
  reviews: [],
  review: {} as any,
  error: false,
  loading: false,
}

const approvalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ApprovalActionType.APPROVALS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }
    case ApprovalActionType.APPROVAL_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }
    case ApprovalActionType.APPROVAL_ADDED:
    case ApprovalActionType.APPROVAL_UPDATED:
    case ApprovalActionType.APPROVAL_DELETED:
    case ApprovalActionType.REVIEWED:
      return {
        ...state,
        loading: false,
      }

    case ApprovalActionType.APPROVAL_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case ApprovalActionType.APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
      }
    case ApprovalActionType.REVIEWS_FETCHED:
      return {
        ...state,
        reviews: action.payload,
      }
    case ApprovalActionType.REVIEW_FETCHED:
      return {
        ...state,
        review: action.payload,
      }
    case ApprovalActionType.APPROVAL_RESET:
      return {
        ...state,
        single: {},
      }
    case ApprovalActionType.APPROVALS_RESET:
      return {
        ...state,
        all: [],
      }
    case ApprovalActionType.REVIEWS_RESET:
      return {
        ...state,
        reviews: [],
      }
    case ApprovalActionType.REVIEW_RESET:
      return {
        ...state,
        review: {},
      }
    default:
      return {
        ...state,
      }
  }
}

export default approvalReducer
