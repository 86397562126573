export enum RoutePath {
  HOME = '/',
  WORK_TRIPS = '/workTrip',
  VEHICLES = '/vehicles',
  POSITIONS = '/posisi',
  DIVISIONS = '/divisi',
  WORK_TIMES = '/worktime',
  HOLIDAY = '/holiday',
  LOCATIONS = '/location',
  SCHEDULES = '/schedule',
  WORKGROUPS = '/workgroup',
  APPROVALS = '/approval',
  ATTENDANCES = '/attendance',
  ISSUES = '/issue',
  EMPLOYEES = '/employee',
  MESSAGES = '/message',
  LETTERS = '/letter',
  ANNOUNCEMENTS = '/announcement',
  EVENTS = '/event',
  PUNISHMENTS = '/punishment',
  PUNISHMENT_TYPES = '/punishment-type',
  APPROVAL_CC = '/approvalcc',
  APPROVAL_RULE = '/approvalrule',
  NATIONAL_CUTI = '/cuti',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forget',
  RESET_FORGOTTEN_PASSWORD = '/reset',
  HIRUPIRA = '/hirupira',
  TICKET = '/ticket',
  TICKET_ADMIN = '/ticket-admin',
  CHECKPOINT = '/checkpoint',
  SIGNATURE = '/signature',
}
