import PopupContextState from 'common/component/util/popup/PopupContextState'
import { createContext } from 'react'

const PopupContext = createContext<PopupContextState>({
  Component: null,
  props: {},
  showPopup: () => {},
  hidePopup: () => {},
})

export default PopupContext
