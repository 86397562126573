import { PunishmentActionType } from './PunishmentActionType'
import PunishmentState from './contract/PunishmentState'

const initialState: PunishmentState = {
  all: [],
  allDeleted: [],
  allResponses: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const punishmentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PunishmentActionType.DELETED_PUNISHMENTS_FETCHED:
    case PunishmentActionType.PUNISHMENTS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case PunishmentActionType.DELETED_PUNISHMENT_FETCHED:
    case PunishmentActionType.PUNISHMENT_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case PunishmentActionType.PUNISHMENT_ADDED:
    case PunishmentActionType.PUNISHMENT_DELETED:
    case PunishmentActionType.PUNISHMENT_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case PunishmentActionType.PUNISHMENT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case PunishmentActionType.PUNISHMENT_ERROR:
      return {
        ...state,
        loading: false,
      }

    case PunishmentActionType.PUNISHMENT_RESET:
      return {
        ...state,
        single: {},
      }

    case PunishmentActionType.PUNISHMENTS_RESET:
      return {
        ...state,
        all: [],
      }

    case PunishmentActionType.PUNISHMENT_RESPONSES_FETCHED:
      return {
        ...state,
        allResponses: action.payload || [],
        loading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default punishmentReducer
