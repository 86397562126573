import { HolidayActionType } from './HolidayActionType'
import HolidayState from './contract/HolidayState'

const initialState: HolidayState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const holidayReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HolidayActionType.DELETED_HOLIDAYS_FETCHED:
    case HolidayActionType.HOLIDAYS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case HolidayActionType.DELETED_HOLIDAY_FETCHED:
    case HolidayActionType.HOLIDAY_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case HolidayActionType.HOLIDAY_ADDED:
    case HolidayActionType.HOLIDAY_DELETED:
    case HolidayActionType.HOLIDAY_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case HolidayActionType.HOLIDAY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case HolidayActionType.HOLIDAY_ERROR:
      return {
        ...state,
        loading: false,
      }

    case HolidayActionType.HOLIDAY_RESET:
      return {
        ...state,
        single: {},
      }

    case HolidayActionType.HOLIDAYS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default holidayReducer
