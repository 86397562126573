import { LocationActionType } from './LocationActionType'
import LocationState from './contract/LocationState'

const initialState: LocationState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const locationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LocationActionType.DELETED_LOCATIONS_FETCHED:
    case LocationActionType.LOCATIONS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case LocationActionType.DELETED_LOCATION_FETCHED:
    case LocationActionType.LOCATION_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case LocationActionType.LOCATION_ADDED:
    case LocationActionType.LOCATION_DELETED:
    case LocationActionType.LOCATION_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case LocationActionType.LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case LocationActionType.LOCATION_ERROR:
      return {
        ...state,
        loading: false,
      }

    case LocationActionType.LOCATION_RESET:
      return {
        ...state,
        single: {},
      }

    case LocationActionType.LOCATIONS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default locationReducer
