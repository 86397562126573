import ModalContextState from 'common/component/util/modal/ModalContextState'
import { createContext } from 'react'

const ModalContext = createContext<ModalContextState>({
  Component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
})

export default ModalContext
