import PopupContext from 'common/component/util/popup/PopupContext'
import PopupContextState from 'common/component/util/popup/PopupContextState'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

class PopupProviderC extends React.Component<
  RouteComponentProps,
  PopupContextState
> {
  showPopup<P>(Component: React.ComponentType<any>, props: P) {
    this.setState({
      Component,
      props,
    })
  }

  hidePopup() {
    this.setState({
      Component: null,
      props: {},
    })
  }

  constructor(props: RouteComponentProps) {
    super(props)

    this.state = {
      Component: null,
      props: this.props,
      showPopup: this.showPopup.bind(this),
      hidePopup: this.hidePopup.bind(this),
    }
  }

  componentDidUpdate(prevProps: Readonly<any>) {
    const { pathname } = this.props.location
    const { pathname: prevPathname } = prevProps.location

    if (pathname !== prevPathname)
      this.setState({
        Component: null,
        props: {},
      })
  }

  render() {
    return (
      <PopupContext.Provider value={this.state}>
        {this.props.children}
      </PopupContext.Provider>
    )
  }
}

export const PopupProvider = withRouter(PopupProviderC)
