import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { RootState } from 'redux/contract/RootState'
import reducers from 'redux/reducers'

import { MODE } from '../api/CSAPI/config'

const rootState: RootState = {}

//export the redux
export const store = createStore(
  reducers,
  rootState,
  MODE !== 'production'
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk),
)
