export enum MessageEvent {
  MESSAGES_FETCHED = 'MESSAGES_FETCHED',
  MESSAGE_FETCHED = 'MESSAGE_FETCHED',
  DELETED_MESSAGES_FETCHED = 'DELETED_MESSAGES_FETCHED',
  DELETED_MESSAGE_FETCHED = 'DELETED_MESSAGE_FETCHED',
  MESSAGE_LOADING = 'MESSAGE_LOADING',
  MESSAGE_ERROR = 'MESSAGE_ERROR',
  MESSAGE_ADDED = 'MESSAGE_ADDED',
  MESSAGE_UPDATED = 'MESSAGE_UPDATED',
  MESSAGE_DELETED = 'MESSAGE_DELETED',
  MESSAGE_RESTORED = 'MESSAGE_RESTORED',
  MESSAGE_RESET = 'MESSAGE_RESET',
  MESSAGES_RESET = 'MESSAGES_RESET',
}
