export enum LocationActionType {
  LOCATIONS_FETCHED = 'LOCATIONS_FETCHED',
  LOCATION_FETCHED = 'LOCATION_FETCHED',
  DELETED_LOCATIONS_FETCHED = 'DELETED_LOCATIONS_FETCHED',
  DELETED_LOCATION_FETCHED = 'DELETED_LOCATION_FETCHED',
  LOCATION_LOADING = 'LOCATION_LOADING',
  LOCATION_ERROR = 'LOCATION_ERROR',
  LOCATION_ADDED = 'LOCATION_ADDED',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
  LOCATION_DELETED = 'LOCATION_DELETED',
  LOCATION_RESTORED = 'LOCATION_RESTORED',
  LOCATION_RESET = 'LOCATION_RESET',
  LOCATIONS_RESET = 'LOCATIONS_RESET',
}
