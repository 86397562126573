import React from 'react'

import ModalConsumer from './ModalConsumer'

const ModalRoot: React.FC = () => {
  return (
    <ModalConsumer>
      {({ Component, props, hideModal }) =>
        Component ? (
          <Component
            {...props}
            onCloseRequest={(data: any) => {
              if (props.onCloseRequest) props.onCloseRequest(data)
              hideModal()
            }}
          />
        ) : null
      }
    </ModalConsumer>
  )
}

export default ModalRoot
