import { TableActionType } from 'common/component/view/dataVisualizer/table/state/table/TableActionType'
import { TableStates } from 'common/component/view/dataVisualizer/table/state/table/contract/TableStates'
import _ from 'lodash'
import { ReducerActionType } from 'redux/contract/ReducerActionType'

const initialState: TableStates = {}

const tableReducer = (
  state = initialState,
  action: ReducerActionType<TableActionType>,
) => {
  const tableID = _.get(action.payload, 'id')

  if (!tableID) return state

  switch (action.type) {
    case TableActionType.SORT_TABLE:
      return {
        ...state,
        [tableID]: {
          sort: {
            path: _.get(action.payload, 'path'),
            mode: _.get(action.payload, 'mode'),
          },
          pagination: _.get(state, `${tableID}.pagination`) || {},
          search: _.get(state, `${tableID}.search`) || {},
        },
      }
    case TableActionType.PAGINATE_TABLE:
      return {
        ...state,
        [tableID]: {
          sort: _.get(state, `${tableID}.sort`) || {},
          pagination: {
            pointer: _.get(action.payload, 'pointer') || 0,
            perPage: _.get(action.payload, 'perPage') || 10,
          },
          search: _.get(state, `${tableID}.search`) || {},
        },
      }
    case TableActionType.SEARCH_TABLE:
      return {
        ...state,
        [tableID]: {
          sort: _.get(state, `${tableID}.sort`) || {},
          pagination: _.get(state, `${tableID}.pagination`) || {},
          search: {
            keyword: _.get(action.payload, 'keyword') || '',
          },
        },
      }
    default:
      return state
  }
}

export default tableReducer
