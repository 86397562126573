import { ApprovalCcEventType } from './ApprovalCcEventType'
import ApprovalCcState from './contract/ApprovalCcState'

const initialState: ApprovalCcState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const approvalCcReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ApprovalCcEventType.DELETED_APPROVAL_CCS_FETCHED:
    case ApprovalCcEventType.APPROVAL_CCS_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case ApprovalCcEventType.DELETED_APPROVAL_CC_FETCHED:
    case ApprovalCcEventType.APPROVAL_CC_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case ApprovalCcEventType.APPROVAL_CC_ADDED:
    case ApprovalCcEventType.APPROVAL_CC_DELETED:
    case ApprovalCcEventType.APPROVAL_CC_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case ApprovalCcEventType.APPROVAL_CC_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case ApprovalCcEventType.APPROVAL_CC_ERROR:
      return {
        ...state,
        loading: false,
      }

    case ApprovalCcEventType.APPROVAL_CC_RESET:
      return {
        ...state,
        single: {},
      }

    case ApprovalCcEventType.APPROVAL_CCS_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default approvalCcReducer
