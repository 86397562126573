import RightSheetContext from 'common/component/util/rightSheet/RightSheetContext'
import RightSheetContextState from 'common/component/util/rightSheet/RightSheetContextState'
import React from 'react'

export class RightSheetProvider extends React.Component<
  any,
  RightSheetContextState
> {
  showRightSheet<P>(Component: React.ComponentType<any>, props: P) {
    this.setState({
      Component,
      props,
    })
  }

  hideRightSheet() {
    this.setState({
      Component: null,
      props: {},
    })
  }

  state = {
    Component: null,
    props: this.props,
    showRightSheet: this.showRightSheet.bind(this),
    hideRightSheet: this.hideRightSheet.bind(this),
  }

  render() {
    return (
      <RightSheetContext.Provider value={this.state}>
        {this.props.children}
      </RightSheetContext.Provider>
    )
  }
}
