import ModalContext from 'common/component/util/modal/ModalContext'
import ModalContextState from 'common/component/util/modal/ModalContextState'
import React from 'react'

export class ModalProvider extends React.Component<any, ModalContextState> {
  showModal<P>(Component: React.ComponentType<any>, props: P) {
    this.setState({
      Component,
      props,
    })
  }

  hideModal() {
    this.setState({
      Component: null,
      props: {},
    })
  }

  state = {
    Component: null,
    props: this.props,
    showModal: this.showModal.bind(this),
    hideModal: this.hideModal.bind(this),
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}
