export enum AttendanceActionType {
  ATTENDANCES_REPLACED = 'ATTENDANCES_REPLACED',
  ATTENDANCES_FETCHED = 'ATTENDANCES_FETCHED',
  ATTENDANCE_FETCHED = 'ATTENDANCE_FETCHED',
  ATTENDANCE_APPROVALS_FETCHED = 'ATTENDANCE_APPROVALS_FETCHED',
  ATTENDANCE_LOADING = 'ATTENDANCE_LOADING',
  ATTENDANCE_ERROR = 'ATTENDANCE_ERROR',
  ATTENDANCE_DELETED = 'ATTENDANCE_DELETED',
  ATTENDANCE_ADDED = 'ATTENDANCE_ADDED',
  ATTENDANCE_UPDATED = 'ATTENDANCE_UPDATED',
  RESET_ATTENDANCES = 'RESET_ATTENDANCES',
  RESET_ATTENDANCE_APPROVALS = 'RESET_ATTENDANCE_APPROVALS',
}
