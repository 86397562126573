export enum IssueActionType {
  ISSUES_FETCHED = 'ISSUES_FETCHED',
  ISSUE_FETCHED = 'ISSUE_FETCHED',
  DELETED_ISSUES_FETCHED = 'DELETED_ISSUES_FETCHED',
  DELETED_ISSUE_FETCHED = 'DELETED_ISSUE_FETCHED',
  ISSUE_LOADING = 'ISSUE_LOADING',
  ISSUE_ERROR = 'ISSUE_ERROR',
  ISSUE_ADDED = 'ISSUE_ADDED',
  ISSUE_UPDATED = 'ISSUE_UPDATED',
  ISSUE_DELETED = 'ISSUE_DELETED',
  ISSUE_RESTORED = 'ISSUE_RESTORED',
  ISSUE_RESET = 'ISSUE_RESET',
  ISSUES_RESET = 'ISSUES_RESET',
}
