import { WorkTimeActionType } from './WorkTimeActionType'
import WorkTimeState from './contract/WorkTimeState'

const initialState: WorkTimeState = {
  all: [],
  allDeleted: [],
  single: {} as any,
  deletedSingle: {} as any,
  error: false,
  loading: false,
}

const workTimeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WorkTimeActionType.DELETED_WORKTIMES_FETCHED:
    case WorkTimeActionType.WORKTIMES_FETCHED:
      return {
        ...state,
        all: action.payload || [],
        loading: false,
      }

    case WorkTimeActionType.DELETED_WORKTIME_FETCHED:
    case WorkTimeActionType.WORKTIME_FETCHED:
      return {
        ...state,
        single: action.payload || {},
        loading: false,
      }

    case WorkTimeActionType.WORKTIME_ADDED:
    case WorkTimeActionType.WORKTIME_DELETED:
    case WorkTimeActionType.WORKTIME_RESTORED:
    case WorkTimeActionType.WORKTIME_UPDATED:
      return {
        ...state,
        loading: false,
      }

    case WorkTimeActionType.WORKTIME_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case WorkTimeActionType.WORKTIME_ERROR:
      return {
        ...state,
        loading: false,
      }

    case WorkTimeActionType.WORKTIME_RESET:
      return {
        ...state,
        single: {},
      }

    case WorkTimeActionType.WORKTIMES_RESET:
      return {
        ...state,
        all: [],
      }

    default:
      return {
        ...state,
      }
  }
}

export default workTimeReducer
