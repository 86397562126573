export enum ScheduleActionType {
  SCHEDULES_FETCHED = 'SCHEDULES_FETCHED',
  SCHEDULE_FETCHED = 'SCHEDULE_FETCHED',
  DELETED_SCHEDULES_FETCHED = 'DELETED_SCHEDULES_FETCHED',
  DELETED_SCHEDULE_FETCHED = 'DELETED_SCHEDULE_FETCHED',
  SCHEDULE_LOADING = 'SCHEDULE_LOADING',
  SCHEDULE_ERROR = 'SCHEDULE_ERROR',
  SCHEDULE_ADDED = 'SCHEDULE_ADDED',
  SCHEDULE_UPDATED = 'SCHEDULE_UPDATED',
  SCHEDULE_DELETED = 'SCHEDULE_DELETED',
  SCHEDULE_RESTORED = 'SCHEDULE_RESTORED',
  SCHEDULE_RESET = 'SCHEDULE_RESET',
  SCHEDULES_RESET = 'SCHEDULES_RESET',
}
