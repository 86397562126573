import authReducer from 'auth/state/authReducer'
import tableReducer from 'common/component/view/dataVisualizer/table/state/table/TableReducer'
import employeeReducer from 'module/employee/state/EmployeeReducer'
import positionReducer from 'module/position/state/PositionReducer'
import punishmentTypeReducer from 'module/punishmentType/state/PunishmentTypeReducer'
import { combineReducers } from 'redux'

import announcementReducer from '../module/announcement/state/announcementReducer'
import approvalReducer from '../module/approval/state/approvalReducer'
import approvalCcReducer from '../module/approvalCc/state/ApprovalCcReducer'
import approvalRuleReducer from '../module/approvalRule/state/ApprovalRuleReducer'
import attendanceReducer from '../module/attendance/state/attendancerReducer'
import divisionReducer from '../module/division/state/DivisionReducer'
import eventReducer from '../module/event/state/eventReducer'
import holidayReducer from '../module/holiday/state/holidayReducer'
import issueReducer from '../module/issue/state/issueReducer'
import locationReducer from '../module/location/state/locationReducer'
import messageReducer from '../module/message/state/messageReducer'
import nationalCutiReducer from '../module/nationalCuti/state/NationalCutiReducer'
import punishmentReducer from '../module/punishment/state/PunishmentReducer'
import scheduleReducer from '../module/schedule/state/scheduleReducer'
import workTimeReducer from '../module/workTime/state/WorkTimeReducer'
import workgroupReducer from '../module/workgroup/state/workgroupReducer'

const reducers = combineReducers({
  table: tableReducer,
  employee: employeeReducer,
  auth: authReducer,
  position: positionReducer,
  division: divisionReducer,
  workTime: workTimeReducer,
  holiday: holidayReducer,
  location: locationReducer,
  schedule: scheduleReducer,
  workgroup: workgroupReducer,
  approval: approvalReducer,
  attendance: attendanceReducer,
  issue: issueReducer,
  announcement: announcementReducer,
  event: eventReducer,
  punishment: punishmentReducer,
  nationalCuti: nationalCutiReducer,
  approvalCc: approvalCcReducer,
  approvalRule: approvalRuleReducer,
  message: messageReducer,
  punishmentType: punishmentTypeReducer,
})

export default reducers
