export enum PunishmentTypeActionType {
  PUNISHMENT_TYPES_FETCHED = 'PUNISHMENT_TYPES_FETCHED',
  PUNISHMENT_TYPE_FETCHED = 'PUNISHMENT_TYPE_FETCHED',
  DELETED_PUNISHMENT_TYPES_FETCHED = 'DELETED_PUNISHMENT_TYPES_FETCHED',
  DELETED_PUNISHMENT_TYPE_FETCHED = 'DELETED_PUNISHMENT_TYPE_FETCHED',
  PUNISHMENT_TYPE_LOADING = 'PUNISHMENT_TYPE_LOADING',
  PUNISHMENT_TYPE_ERROR = 'PUNISHMENT_TYPE_ERROR',
  PUNISHMENT_TYPE_ADDED = 'PUNISHMENT_TYPE_ADDED',
  PUNISHMENT_TYPE_UPDATED = 'PUNISHMENT_TYPE_UPDATED',
  PUNISHMENT_TYPE_DELETED = 'PUNISHMENT_TYPE_DELETED',
  PUNISHMENT_TYPE_RESTORED = 'PUNISHMENT_TYPE_RESTORED',
  PUNISHMENT_TYPE_RESET = 'PUNISHMENT_TYPE_RESET',
  PUNISHMENT_TYPES_RESET = 'PUNISHMENT_TYPES_RESET',
}
