export enum EventEvent {
  EVENTS_FETCHED = 'EVENTS_FETCHED',
  EVENT_FETCHED = 'EVENT_FETCHED',
  DELETED_EVENTS_FETCHED = 'DELETED_EVENTS_FETCHED',
  DELETED_EVENT_FETCHED = 'DELETED_EVENT_FETCHED',
  EVENT_LOADING = 'EVENT_LOADING',
  EVENT_ERROR = 'EVENT_ERROR',
  EVENT_ADDED = 'EVENT_ADDED',
  EVENT_UPDATED = 'EVENT_UPDATED',
  EVENT_DELETED = 'EVENT_DELETED',
  EVENT_RESTORED = 'EVENT_RESTORED',
  EVENT_RESET = 'EVENT_RESET',
  EVENTS_RESET = 'EVENTS_RESET',
}
