export enum PunishmentActionType {
  PUNISHMENTS_FETCHED = 'PUNISHMENTS_FETCHED',
  PUNISHMENT_FETCHED = 'PUNISHMENT_FETCHED',
  DELETED_PUNISHMENTS_FETCHED = 'DELETED_PUNISHMENTS_FETCHED',
  DELETED_PUNISHMENT_FETCHED = 'DELETED_PUNISHMENT_FETCHED',
  PUNISHMENT_LOADING = 'PUNISHMENT_LOADING',
  PUNISHMENT_ERROR = 'POSITION_EZRROR',
  PUNISHMENT_ADDED = 'PUNISHMENT_ADDED',
  PUNISHMENT_RESPONSE_ADDED = 'PUNISHMENT_RESPONSE_ADDED',
  PUNISHMENT_UPDATED = 'PUNISHMENT_UPDATED',
  PUNISHMENT_DELETED = 'PUNISHMENT_DELETED',
  PUNISHMENT_RESTORED = 'PUNISHMENT_RESTORED',
  PUNISHMENT_RESET = 'PUNISHMENT_RESET',
  PUNISHMENTS_RESET = 'PUNISHMENTS_RESET',
  PUNISHMENT_RESPONSES_FETCHED = 'PUNISHMENT_RESPONSES_FETCHED',
}
